// @use "@angular/material" as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

// @import "~@angular/material/prebuilt-themes/indigo-pink.css";

/**
 * Material style overwrite
 */
.mat-menu-panel {
  border-radius: 0.8rem !important;
  .mat-menu-content {
    padding: 0.5rem !important;
    button {
      border-radius: 0.5rem;
      padding: 0 12px;
      &.mat-menu-item-submenu-trigger {
        padding-right: 32px;
      }
      mat-icon {
        margin-right: 8px;
      }
    }
  }
}

.mat-dialog-container {
  border-radius: 1rem !important;
}

/**
 * Tailwindcss
 */
[data-theme="liveshare"] .btn {
  height: 2.5rem;
  min-height: 2.5rem;
  min-width: 5rem;
  &.btn-circle {
    width: 2.5rem;
    min-width: 2.5rem;
    &.btn-lg {
      width: 3rem;
      height: 3rem;
    }
  }
  &.btn-xs {
    height: 1.5rem;
    min-height: 1.5rem;
  }
  &.btn[disabled].btn-outline.btn-primary {
    background: transparent;
    .material-icons.text-primary {
      color: #ccc;
    }
  }
}

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  // background-color: #f0f2f5;
}
body {
  margin: 0;
  font-family: "Montserrat-Regular";
}

.full-width {
  width: 100%;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

@keyframes slide {
  100% {
    top: 4%;
  }
}

@keyframes slideComment {
  100% {
    top: 10%;
  }
}

.dialog-bottom-panel {
  position: fixed !important;
  top: 90%;
  height: 96%;
  animation: slide 0.3s forwards;

  .mat-dialog-container {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
    padding-right: 0px;
  }
}

.personalize-panel {
  position: fixed !important;
  top: 90%;
  height: 96%;
  animation: slide 0.3s forwards;

  .mat-dialog-container {
    height: 100%;
    // border-top-left-radius: 7px;
    // border-top-right-radius: 7px;
    // border-bottom-left-radius: 0;
    // border-bottom-right-radius: 0;
    overflow: hidden;
    // padding: 0px;
    // background: none;
  }
}

// .personalize-confirmation,
// .set-value {
//   .mat-dialog-container {
//     border-radius: 7px;
//     padding: 0px;
//     // background: none;
//   }
// }

.welcome-help-popup {
  .mat-dialog-container {
    border-radius: 25px;
  }
}

.preview-brand {
  .mat-dialog-container {
    border-radius: 7px;
    padding: 0px;
    box-shadow: 0 0 350px #000, 0 0 350px #000, 0 0 350px #000;
  }
}

// .plan-dialog {
//   animation: slide 0.3s forwards;

//   .mat-dialog-container {
//     overflow: hidden;
//     padding: 0px;
//   }
// }

.swipeTimeline-option {
  .mat-dialog-container {
    padding: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0 !important;
  }
  app-timelineview-popup {
    width: 100%;
    height: 100%;
  }
}
.purchase-option {
  .mat-dialog-container {
    padding: 0;
    background: #0c0c0c78;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    app-purchase-option,
    app-gift-event-purchase {
      width: 100%;
      height: 100%;
    }
  }
}

.dialog-bottom-panel-top-fill {
  animation: slide 0.3s forwards;

  .mat-dialog-container {
    // overflow: hidden;
    // padding-right: 0px;
    border-radius: 0 !important;
  }
}
.dialog-bottom-panel-view-one-post {
  animation: slide 0.3s forwards;

  .mat-dialog-container {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: rgb(0, 0, 0, 0.9);
    overflow: hidden;
  }
}

.dialog-bottom-panel-single-photo {
  position: fixed !important;
  top: 90%;
  height: 96%;
  animation: slide 0.3s forwards;

  .mat-dialog-container {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
  }
}

.dialog-bottom-panel-post {
  position: fixed !important;
  top: 90%;
  height: 96%;
  animation: slide 0.3s forwards;
}

.dialog-bottom-panel-initial {
  top: 100%;
  height: 100%;
  animation: slide 0.3s forwards;

  .mat-dialog-container {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
    padding-right: 0px;
  }
}

.comment-panel {
  position: fixed !important;
  top: 90%;
  height: 90%;
  animation: slideComment 0.3s forwards;

  .mat-dialog-container {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgb(140 140 140 / 15%);
}

.mat-dialog-container {
  box-shadow: none;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  margin: auto;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
  z-index: 100;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  overflow: hidden;
  cursor: pointer;
}

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input,
textarea,
button {
  outline: none;
}

// button:focus {
//   border: none;
//   outline: none;
//   // padding: 0;
//   background: none;
// }

.retry {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #8b6363;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container {
  display: flex;
  justify-content: center;
}
.loading-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #efefef;
  // #DDDBDD
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 1s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
.strikethrough {
  position: relative;
  font-size: 0.9em;
  font-weight: normal;
  margin-right: 4px;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 40%;
  right: 0;
  border-top: 4px solid;
  border-color: red;
  color: red;

  -webkit-transform: rotate(15deg);
  -moz-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  -o-transform: rotate(15deg);
  transform: rotate(15deg);
}

.fullscreen-dialog {
  height: 90% !important;
  margin-top: 4% !important;
  width: 600px;
  .mat-dialog-container {
    @apply p-2;
  }
}

@media only screen and (max-width: 720px) {
  .fullscreen-dialog {
    max-width: 720px !important;
    max-height: 100% !important;
    width: 100%;
    height: 90% !important;
    bottom: 0;
    position: fixed !important;
    .mat-dialog-container {
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }
  .fullscreen-dialog.cdk-overlay-pane {
    // transform: translateY(-250%);
    animation: slideUp 0.25s forwards;
  }
}

.crop-image-dialog {
  border-radius: 0;
}
